(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("rxjs"), require("rxjs/operators"), require("@stomp/stompjs"));
	else if(typeof define === 'function' && define.amd)
		define("RxStomp", ["rxjs", "rxjs/operators", "@stomp/stompjs"], factory);
	else if(typeof exports === 'object')
		exports["RxStomp"] = factory(require("rxjs"), require("rxjs/operators"), require("@stomp/stompjs"));
	else
		root["RxStomp"] = factory(root["rxjs"], root["rxjs"]["operators"], root["StompJs"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__7__) {
return 